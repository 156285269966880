<template>
    <div>
        <div class="row">
            <div class="p-2 col-md-12">
                <p>{{ $t('devenir-partenaire.contact') }}</p>
            </div>
        </div>
        <div v-if="errors.length > 0" class="row">
            <div class="p-2 col-md-12">
                <div class="alert alert-danger">
                    <ul class="m-0">
                        <li v-for="(error, idx) in errors" :key="'_err' + idx">
                            {{ error }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="p-2 col-md-12">
                <label for="phone" class="form-control-label-profil">{{ $t('user.phone') }}</label>
                <vue-tel-input v-model="userPhone" :default-country="country[$i18n.locale]" :input-options="options" />
            </div>
        </div>
        <div class="row">
            <div class="p-2 col-md-12">
                <v-textfield v-model="skype" class-label="form-control-label-profil" name="Skype" label="Skype">
                    <template #prepend>
                        <i class="fab fa-skype" />
                    </template>
                </v-textfield>
            </div>
        </div>
        <div class="row">
            <div class="p-2 col-md-12">
                <v-checkbox v-model="cgv" :true-value="true" :false-value="false">
                    <a href="#" class="form-check-label" @click="toggleGTCModal()">{{
                        $t('devenir-partenaire.cgv')
                    }}</a>
                </v-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="p-2 col-md-12">
                <button class="btn btn-primary" @click="submit">
                    {{ $t('devenir-partenaire.partneryes') }}
                </button>
            </div>
        </div>

        <div class="row">
            <div class="p-2 col-md-12">
                <h3>{{ $t('devenir-partenaire.descriptiontitle') }}</h3>

                <p>{{ $t('devenir-partenaire.description') }}</p>

                <p>{{ $t('devenir-partenaire.description_02') }}</p>

                <h3>{{ $t('devenir-partenaire.redactiontitle') }}</h3>

                <p>{{ $t('devenir-partenaire.redaction') }}</p>

                <p class="mb-4">
                    {{ $t('devenir-partenaire.redaction_02') }}
                </p>

                <h3>{{ $t('devenir-partenaire.advantagestitle') }}</h3>

                <p>{{ $t('devenir-partenaire.advantages') }}.</p>

                <p>
                    {{ $t('devenir-partenaire.advantages_02') }}.<br />
                    {{ $t('devenir-partenaire.advantages_04') }}
                </p>

                <p class="mb-4">
                    {{ $t('devenir-partenaire.advantages_05') }}
                </p>

                <h3>{{ $t('devenir-partenaire.sitetypetitle') }}</h3>

                <p>{{ $t('devenir-partenaire.sitetype') }}</p>

                <p>{{ $t('devenir-partenaire.sitetype_02') }}</p>

                <p class="mb-4">
                    {{ $t('devenir-partenaire.sitetype_03') }}
                </p>

                <h3>{{ $t('devenir-partenaire.partsitetitle') }}</h3>

                <p>
                    {{ $t('devenir-partenaire.partsite') }}<br />
                    {{ $t('devenir-partenaire.partsite_02') }} <br />
                    {{ $t('devenir-partenaire.partsite_03') }}
                </p>

                <p>{{ $t('devenir-partenaire.welcome') }}!</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapMutations } from 'vuex'

    export default {
        data: function () {
            return {
                errors: [],
                userPhone: '',
                phone: null,
                skype: null,
                cgv: null,
                country: {
                    fr: 'FR',
                    en: 'GB'
                },
                options: {
                    placeholder: this.$t('user.phone'),
                    id: 'phone'
                },
                validated: false
            }
        },
        methods: {
            ...mapActions('auth', ['becomePartner']),
            ...mapActions('auth', ['me']),
            ...mapMutations(['toggleGTCModal']),
            setValid: function (phoneObject) {
                if (phoneObject && phoneObject.valid === true) {
                    this.phone = phoneObject
                } else {
                    this.phone = null
                }
            },

            submit() {
                this.errors = []

                if (!this.userPhone && !this.skype) {
                    this.errors.push('Saisir un numéro de téléphone ou un skype')
                }

                if (!this.cgv) {
                    this.errors.push('Accepter les conditions générales de vente')
                }

                if (this.errors.length === 0) {
                    this.becomePartner({
                        phone: this.userPhone,
                        skype: this.skype
                    }).then(() => {
                        this.me()
                        this.$router.push('/partner/sites')
                    })
                }
            }
        }
    }
</script>

<style></style>
